import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import LinearBottomNavBar from 'components/local/navbar/LinearBottomNavBar';
import Modal from 'components/local/modals/Modal';
import PageLoading from 'components/local/loading/PageLoading';
import ProgressBarLinear from 'components/global/progressBar/ProgressBarLinear';
import SectionWrapper from 'containers/inProgressPage/subcomponents/SectionWrapper';
import Slot from 'containers/inProgressPage/subcomponents/Slot';
import { beforeUnload } from 'containers/inProgressPage/helpers/beforeUnload';
import { formatAnswer } from 'containers/inProgressPage/helpers/formatAnswer';
import { useGlobalEventListener } from 'components/global/hooks/useGlobalEventListener';
import { useHandleRequest } from 'containers/inProgressPage/hooks/useHandleRequest';
import { useHandleSlot } from 'containers/inProgressPage/hooks/useHandleSlot';
import { useSetCurrentSlot } from 'containers/inProgressPage/hooks/useSetCurrentSlot';
import { useHandleSlotRequests } from 'containers/inProgressPage/hooks/useHandleSlotRequests';
import { validateForm } from 'containers/inProgressPage/helpers/validateForm';
import { useAssesmentContext } from 'services/context/assessmentContext/assessmentContext';
import styled from 'styled-components';
import { MAX_FILE_SIZE } from 'utils/constants/constants.js';

const SectionLinear = () => {
  const { state, currentSection, currentSlot } = useSetCurrentSlot();
  const [fileError, setFileError] = useState(null);

  const { currentSlotId, currentSlotIndex, slotIsLoading } = state;
  const { handleSubmitSlot } = useHandleRequest();
  const {
    questionSlot,
    upload,
    banded,
    select,
    modal,
    setModal,
    submitSection,
    setSubmitSection,
    questionType
  } = useHandleSlot(currentSlot);
  const isLastQuestion =
    currentSection?.slot_attempts.length - 1 === currentSlot?.sort_index;
  const formikProps = useFormik({
    initialValues: { [currentSlotId]: banded || upload ? null : [] },
    validationSchema: validateForm(currentSlotId, questionType, currentSlot),
    onSubmit: values => handleSlotModal(values)
  });

  const {
    state: { files },
    dispatch
  } = useAssesmentContext();

  useGlobalEventListener('beforeunload', beforeUnload, window);
  useHandleSlotRequests();

  useEffect(() => {
    dispatch({
      type: 'SET_FILE',
      payload: {}
    });
    if (currentSlot && formikProps) {
      formikProps.resetForm();
      formikProps.setValues({
        [currentSlotId]: banded ? null : []
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlot]);

  useEffect(() => {
    if (submitSection) {
      formikProps.errors[currentSlotId]
        ? handleSubmitSlot(null, submitSection)
        : formikProps.submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitSection]);

  const handleSlotModal = async values => {
    /* NOTE: this logic only supports static, question_select, and question_banded. 
    As support for new slot/question types are added, this will need to be reevaluated. */
    const value = values[currentSlotId];
    const questionLeftBlank =
      questionSlot &&
      (value === null || value === undefined || value.length === 0);
    /* If the value indicates a blank input and it is a timed section, show 
    modal before submitting */
    if (
      currentSection.section.is_timed &&
      questionLeftBlank &&
      !submitSection
    ) {
      setModal(true);
      // Otherwise, submit
    } else {
      handleNextSlot(value);
    }
  };

  const handleNextSlot = async value => {
    const fileKey = Object.keys(files)[0];
    const fileValue = files[fileKey] ?? null;

    if (fileValue instanceof File && fileValue.size > MAX_FILE_SIZE) {
      let sizeInMB = (value.size / (1024 * 1024)).toFixed(2);
      const errorMessage = `There is an error with your file. File size exceeds 30MB, current value is ${sizeInMB} MB`;
      setFileError(errorMessage);
      return;
    }

    setFileError(null);

    const answer = await formatAnswer(
      fileValue instanceof File ? fileValue : value,
      currentSlot,
      select,
      banded
    );

    if (answer instanceof Error) {
      setFileError('There was an error uploading the file');
      return;
    }

    handleSubmitSlot(answer, submitSection);
  };

  if (slotIsLoading || !currentSlotId) return <PageLoading />;

  return (
    <SectionWrapper
      formikProps={formikProps}
      setSubmitSection={setSubmitSection}
    >
      <form onSubmit={formikProps.handleSubmit}>
        {modal && (
          <Modal
            handleAccept={() => handleNextSlot(formikProps.values)}
            handleClose={() => setModal(false)}
            modalType={isLastQuestion ? 'submitSection' : 'submitSlot'}
          />
        )}
        <ProgressBarLinear
          slots={currentSection.slot_attempts.length}
          currentSlot={currentSlotIndex}
        />
        <Slot formikProps={formikProps} />
        <LinearBottomNavBar formikProps={formikProps} />
      </form>
      {fileError && <StyledErrorMessage>{fileError}</StyledErrorMessage>}
    </SectionWrapper>
  );
};

export const StyledErrorMessage = styled.p`
  color: red;
`;

export default SectionLinear;
